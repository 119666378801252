<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>学员中心</a-breadcrumb-item>
            <a-breadcrumb-item>结转</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button>确认结转</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                        <a-form-item>
                            <a-input v-model='searchParams.search.keyword'  style="width: 160px" allowClear placeholder="学员名称/手机号"></a-input>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div style="padding:15px 0">
                <a-row>
                    <a-col :span="4">学号：</a-col>
                    <a-col :span="4">姓名：</a-col>
                    <a-col :span="4">性别：</a-col>
                    <a-col :span="4">手机号：</a-col>
                    <a-col :span="4">电子钱包余额：0.00</a-col>
                    <a-col :span="4">结转日期：2021-05-11</a-col>
                </a-row>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="middle" :pagination="false" :bordered='false' rowKey="task_id"
                    :selection="{key: 'key'}" :columns="columns" :dataSource="list">
                </a-table>
            </div>
        </div>
        <a-row :gutter="12" style="margin-top:15px">
            <a-col :span="12">
                <a-card title="经办信息" style="width: 100%">
                    <p>card content</p>
                    <p>card content</p>
                    <p>card content</p>
                </a-card>
            </a-col>
            <a-col :span="12">
                <a-card title="结算信息" style="width: 100%">
                    <p>card content</p>
                    <p>card content</p>
                    <p>card content</p>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
const columns = [
  {title: '交易日期', dataIndex: 'customer_name',key: '1'},
  {title: '收据号', dataIndex: 'customer_name',key: '2'},
  {title: '项目名称', dataIndex: 'customer_name',key: '3'},
  {title: '购买数量', dataIndex: 'customer_name',key: '4'},
  {title: '购买单价', dataIndex: 'customer_name',key: '5'},
  {title: '课消数量', dataIndex: 'customer_name',key: '6'},
  {title: '计费单价', dataIndex: 'customer_name',key: '7'},
  {title: '标准价', dataIndex: 'customer_name',key: '8'},
  {title: '单位', dataIndex: 'customer_name',key: '9'},
  {title: '剩余数量', dataIndex: 'customer_name',key: '10'},
  {title: '可结转金额', dataIndex: 'customer_name',key: '11'},
  {title: '结转数量', dataIndex: 'customer_name',key: '12'},
  {title: '结转金额', dataIndex: 'customer_name',key: '13'},
  {title: '结转溢价', dataIndex: 'customer_name',key: '14'},
]
    export default {
        name:'carryForward',
        data() {
            return {
                loading:false,
                columns,
                list: [],
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {},
                    "sort": ''
                },
            }
        },
        methods: {
            searchList() {
                
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>